import { gql } from 'urql'

const QueryProfileShow = gql`
  query ($email: String) {
    profilesShow(email: $email) {
      email
      id
      defaultAccessibleApplicantId
    }
  }
`

export default QueryProfileShow
