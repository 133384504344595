const MutationCreateProfile = `
  mutation (
    $jobId: Int,
    $fullName: String!,
    $phoneNumber: String,
    $email: String,
    $resumeFile: File,
    $profileTalentPoolIds: [Int!],
  ) {
    profilesCreate(
      input: {
        jobId: $jobId,
        fullName: $fullName,
        phoneNumber: $phoneNumber,
        email: $email,
        resumeFile: $resumeFile,
        profileTalentPoolIds: $profileTalentPoolIds,
      }
    ) {
      profile {
        id
        applicants {
          id
        }
      }
    }
  }
`

export default MutationCreateProfile
