import { gql } from 'urql'

const QueryCompanyListOptions = gql`
  query ($limit: Int, $page: Int, $search: String) {
    companiesList(limit: $limit, page: $page, search: $search) {
      collection {
        id
        name
        logoVariants
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryCompanyListOptions
