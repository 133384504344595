import { useCallback } from 'react'
import { ROLE_CODE } from '~/core/constants/role'
import useBoundStore from '~/lib/store'

type Roles = 'default' | 'limited_member' | 'admin' | 'member'
interface SwitchRoleMessageMap<T> {
  default: T
  limited_member?: T
  admin?: T
  member?: T
}
const useSwitchRole = (): (<T>(messageMap: SwitchRoleMessageMap<T>) => T) => {
  const { currentRole } = useBoundStore()
  const role = (
    currentRole?.code
      ? {
          [ROLE_CODE.Admin]: 'admin',
          [ROLE_CODE.LimitedMember]: 'limited_member',
          [ROLE_CODE.Member]: 'member'
        }[currentRole?.code] || 'default'
      : 'default'
  ) as Roles
  const func: <T>(messageMap: SwitchRoleMessageMap<T>) => T = (messageMap) => {
    return messageMap[role] || messageMap.default
  }
  return useCallback(func, [role])
}
export default useSwitchRole
