import withTranslateProvider from 'src/hoc/with-translate-provider'
import HeadMetaTags from '~/components/HeadMetaTags'
import configuration from '~/configuration'
import If from '~/core/ui/If'
import JobsManagementContainer from '~/features/jobs'
import useBoundStore from '~/lib/store'

import en_referrals from '../../public/locales/en/referrals.json'
import vn_referrals from '../../public/locales/vn/referrals.json'
import ja_referrals from '../../public/locales/ja/referrals.json'
import { useTranslation } from 'react-i18next'
import { PUBLIC_APP_NAME } from '~/core/constants/env'

function JobsPage() {
  const { t } = useTranslation()
  const currentRole = useBoundStore((state) => state.currentRole)

  return (
    <>
      <HeadMetaTags title={`${t(`common:seo:jobs`, { PUBLIC_APP_NAME })}`} />

      <If condition={currentRole?.id}>
        <JobsManagementContainer />
      </If>
    </>
  )
}

export default withTranslateProvider(JobsPage, {
  en: { referrals: en_referrals },
  vn: { referrals: vn_referrals },
  ja: { referrals: ja_referrals }
})
