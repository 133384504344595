import { gql } from 'urql'

export type QueryAgencyLocationVariable = {
  limit?: number
  page?: number
  search?: string
  tenantSlug?: string
}
const QueryAgencyLocations = gql<
  {
    companyLocsByCountryStateList: {
      collection: {
        id: number
        name: string
        address: string
        state: string
        country: string
      }[]
      metadata: { totalCount: number }
    }
  },
  QueryAgencyLocationVariable
>`
  query ($limit: Int!, $page: Int!, $search: String, $tenantSlug: String!) {
    companyLocsByCountryStateList(
      limit: $limit
      page: $page
      search: $search
      tenantSlug: $tenantSlug
    ) {
      collection {
        id
        country
        state
        countryStateId
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryAgencyLocations
