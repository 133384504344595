import { StateCreator } from 'zustand'
import { CareerPageSettingType } from '../types'

export interface CareerSettingSlice {
  careerPageSetting: CareerPageSettingType | undefined
  setCareerPageSetting: (careerPageSetting: CareerPageSettingType) => void
}

export const createCareerPageSettingSlice: StateCreator<
  CareerSettingSlice,
  [],
  [],
  CareerSettingSlice
> = (set: Function) => ({
  careerPageSetting: undefined,
  setCareerPageSetting: (data: CareerPageSettingType) =>
    set({ careerPageSetting: data })
})
