import { create } from 'zustand'
import {
  createCareerPageSettingSlice,
  CareerSettingSlice
} from './career-setting.slice'
const useCareerSettingStore = create<CareerSettingSlice>()((...a) => ({
  ...createCareerPageSettingSlice(...a)
}))

export default useCareerSettingStore
