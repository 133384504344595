import { ReactElement, RefObject, useEffect, useRef, useState } from 'react'

const ComputeInputRightPadding = (props: {
  children: (args: {
    inputStyle: { paddingRight?: number }
    rightInputRef: RefObject<HTMLDivElement>
    createOnPaddingChange: <A>(func: (a: A) => void) => (a: A) => void
  }) => ReactElement
}) => {
  const [value, setvalue] = useState<any>()
  const [width, setWidth] = useState<number | undefined>()
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth)
    }
  }, [value, ref])
  return props.children({
    inputStyle: { paddingRight: width },
    createOnPaddingChange: (func) => (newValue) => {
      setvalue(newValue)
      func(newValue)
    },
    rightInputRef: ref
  })
}
export default ComputeInputRightPadding
