import { FC } from 'react'
import { Control, Controller, FieldValues, FormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import configuration from '~/configuration'
import { AsyncMultipleSearchWithSelect } from '~/core/ui/AsyncMultipleSearchWithSelect'
import { AsyncSingleSearchWithSelect } from '~/core/ui/AsyncSingleSearchWithSelect'
import { Button } from '~/core/ui/Button'
import { UploadFileDragAndDrop } from '~/core/ui/FileDragAndDrop'
import { FormControlItem } from '~/core/ui/FormControlItem'
import { Input } from '~/core/ui/Input'
import { PhoneInput } from '~/core/ui/PhoneInput'
import { IPromiseSearchOption } from '~/core/ui/Select'
import { TextButton } from '~/core/ui/TextButton'
import { convertStringArrayToArray } from '~/core/utilities/common'
import useCandidateProfile from '~/lib/features/candidates/hooks/use-query-candidate'
import { AddCandidateFormType } from '~/lib/features/candidates/types'

const CreateCandidateForm: FC<{
  defaultValue?: AddCandidateFormType
  onOpenChange: (value: boolean) => void
  formState: FormState<AddCandidateFormType>
  control: Control<FieldValues, any>
  isLoading?: boolean
  promiseJobListOptions?: (
    params: IPromiseSearchOption
  ) => Promise<{ metadata?: { totalCount: number }; collection: never[] }>
}> = ({
  defaultValue = {},
  onOpenChange,
  control,
  formState,
  isLoading,
  promiseJobListOptions
}) => {
  const { t, i18n } = useTranslation()
  const { promiseTalentPoolOptions } = useCandidateProfile({
    sourced: '',
    disabledOption: (defaultValue?.profileTalentPoolIds || []).map((item) =>
      item.value.toString()
    )
  })

  return (
    <>
      <div className="mb-4">
        <Controller
          control={control}
          name="fullName"
          defaultValue={defaultValue?.fullName || ''}
          render={({ field: { onChange, value } }) => (
            <FormControlItem
              labelRequired
              label={`${t('form:fullNameProfileFieldLabel')}`}
              destructive={formState.errors && !!formState.errors?.fullName}
              destructiveText={
                formState.errors &&
                (formState.errors?.fullName?.message as string)
              }>
              <Input
                autoFocus
                placeholder={`${t(
                  'form:add_candidate_name_field_placeholder'
                )}`}
                size="sm"
                onChange={onChange}
                value={value}
                destructive={formState.errors && !!formState.errors?.fullName}
              />
            </FormControlItem>
          )}
        />
      </div>
      <div className="mb-4">
        <Controller
          control={control}
          name="email"
          defaultValue={defaultValue?.email || ''}
          render={({ field: { onChange, value } }) => {
            const parseJSON = convertStringArrayToArray(
              formState.errors?.email?.message as string
            )

            return (
              <FormControlItem
                label={`${t('label:email')}`}
                destructive={formState.errors && !!formState.errors?.email}
                destructiveText={
                  parseJSON?.length ? (
                    <div className="flex items-center">
                      {`${t('label:emailExists')} `}
                      <TextButton
                        onClick={() =>
                          window.open(
                            configuration.path.candidates.detail(
                              parseJSON?.[0],
                              parseJSON?.[1]
                            ),
                            '_blank'
                          )
                        }
                        label={`${t('button:viewProfile')}`}
                        size="md"
                        underline={false}
                        className="ml-1"
                      />
                    </div>
                  ) : (
                    formState.errors?.email?.message
                  )
                }>
                <Input
                  placeholder={`${t(
                    'form:add_candidate_email_field_placeholder'
                  )}`}
                  size="sm"
                  onChange={onChange}
                  value={value}
                />
              </FormControlItem>
            )
          }}
        />
      </div>
      <div className="mb-4">
        <Controller
          control={control}
          name="countryCode"
          defaultValue=""
          render={({ field: { onChange: onChangeCountryCode } }) => (
            <Controller
              control={control}
              name="phoneNumber"
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <FormControlItem
                  destructive={
                    formState.errors && !!formState.errors.phoneNumber
                  }
                  destructiveText={
                    formState.errors &&
                    (formState.errors.phoneNumber?.message as string)
                  }
                  label={`${t('form:phoneNumberProfileFieldLabel')}`}>
                  <PhoneInput
                    size="sm"
                    country={
                      i18n.language === 'ja'
                        ? 'jp'
                        : i18n.language === 'en'
                        ? 'vn'
                        : i18n.language
                    }
                    onChange={onChange}
                    onChangeCountryCode={onChangeCountryCode}
                    value={value}
                    placeholder={`${t('form:phone_number_field_placeholder')}`}
                  />
                </FormControlItem>
              )}
            />
          )}
        />
      </div>
      <div className="mb-4">
        <Controller
          control={control}
          name="resumeFile"
          render={({ field: { onChange, value } }) => {
            return (
              <FormControlItem
                label={`${t('careers:applied:resumeCV')}`}
                destructive={formState.errors && !!formState.errors?.resumeFile}
                destructiveText={
                  formState.errors &&
                  (formState.errors?.resumeFile?.message as string)
                }>
                <UploadFileDragAndDrop
                  className="w-full"
                  configText={{
                    clickToUpload: `${t('label:dragAndDrop:clickToUpload')}`,
                    orDragAndDrop: `${t('label:dragAndDrop:orDragAndDrop')}`,
                    delete: `${t('tooltip:delete')}`,
                    tryAgain: `${t('tooltip:tryAgain')}`,
                    uploadANewFile: `${t('tooltip:uploadANewFile')}`
                  }}
                  dragNDropHelperText={`${t(
                    'careers:applied:dragNDropTypeFiles'
                  )}`}
                  maximumSizeFile="10MB"
                  maximumFiles={1}
                  accept={{
                    'application/pdf': ['.pdf'],
                    'application/msword': ['.doc'],
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                      ['.docx']
                  }}
                  files={value ? (Array.isArray(value) ? value : [value]) : []}
                  onChange={onChange}
                  destructive={!!formState.errors?.resumeFile}
                />
              </FormControlItem>
            )
          }}
        />
      </div>
      <div className="mb-4 mr-4 w-full">
        <div
          className={defaultValue?.jobId ? 'hidden' : ''}
          style={{ display: defaultValue?.jobId ? 'none' : '' }}>
          <Controller
            control={control}
            name="jobId"
            defaultValue={defaultValue?.jobId || []}
            render={({ field: { onChange, value } }) => {
              return (
                <FormControlItem
                  label={`${t('form:add_candidate_job_field_label')}`}>
                  <AsyncSingleSearchWithSelect
                    promiseOptions={promiseJobListOptions}
                    size="sm"
                    onChange={(newValue) =>
                      onChange(newValue ? [newValue] : [])
                    }
                    placeholder={`${t('label:placeholder:select')}`}
                    value={value}
                    menuPlacement="top"
                    configSelectOption={{
                      supportingText: [
                        'name',
                        'shortName',
                        'description',
                        'descriptionHelpName'
                      ],
                      dot: true
                    }}
                    classNameOverride={{
                      loadingMessage: `${t('label:loading')}`,
                      noOptionsMessage: `${t('label:noOptions')}`
                    }}
                  />
                </FormControlItem>
              )
            }}
          />
        </div>
      </div>
      <div className="mb-4">
        <Controller
          control={control}
          name="profileTalentPoolIds"
          defaultValue={defaultValue?.profileTalentPoolIds || []}
          render={({ field: { onChange, value } }) => {
            return (
              <FormControlItem label={`${t('label:talentPools')}`}>
                <AsyncMultipleSearchWithSelect
                  promiseOptions={promiseTalentPoolOptions}
                  size="sm"
                  onChange={(newValue) => {
                    if (Array.isArray(newValue) && newValue?.length === 0) {
                      onChange(
                        defaultValue?.profileTalentPoolIds?.filter(
                          (item) => item.disabled
                        )
                      )
                    } else {
                      onChange(newValue)
                    }
                  }}
                  placeholder={`${t('label:placeholder:select')}`}
                  value={value}
                  menuPlacement="top"
                  configSelectOption={{
                    option: 'checkbox',
                    supportingText: ['name']
                  }}
                  classNameOverride={{
                    loadingMessage: `${t('label:loading')}`,
                    noOptionsMessage: `${t('label:noOptions')}`
                  }}
                />
              </FormControlItem>
            )
          }}
        />
      </div>
      <div className="flex items-center justify-end pt-6">
        <Button
          type="secondary"
          className="mr-3"
          size="sm"
          onClick={() => onOpenChange(false)}
          label={`${t('button:cancel')}`}
        />
        <Button
          isDisabled={isLoading}
          isLoading={isLoading}
          size="sm"
          htmlType="submit"
          label={`${t('button:add')}`}
        />
      </div>
    </>
  )
}

export default CreateCandidateForm
