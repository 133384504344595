import { FC, ReactNode } from 'react'
import useBoundStore from '~/lib/store'
export enum ROLE {
  LimitedMember = 'Limited Member',
  Admin = 'Admin'
}
const OnlyFor = ({
  children,
  role,
  not
}: {
  role?: string
  not?: string
  children: ReactNode
}) => {
  const { currentRole } = useBoundStore()

  return (!role || currentRole?.code === role) &&
    (!not || currentRole?.code !== not) ? (
    <>{children}</>
  ) : (
    <></>
  )
}
OnlyFor.ROLE = ROLE
export default OnlyFor
